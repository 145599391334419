import {
  init,
  makeFetchTransport,
  makeMultiplexedTransport,
  moduleMetadataIntegration,
  browserTracingIntegration,
} from '@sentry/react';
import { ErrorEvent } from '@sentry/types';
import { AppConfigObj } from './utils/sessionUtils';
import { sentryApiDsnEnv } from './constants/environmentVariables';

const EXTRA_KEY = 'ROUTE_TO';

const transport = makeMultiplexedTransport(makeFetchTransport, (args) => {
  const event = args.getEvent();
  if (event && event.extra && EXTRA_KEY in event.extra && Array.isArray(event.extra[EXTRA_KEY])) {
    return event.extra[EXTRA_KEY];
  }
  return [];
});

export const initSentry = (isDevMode: boolean, appConfig: AppConfigObj) => {
  if (import.meta.env.VITE_SENTRY_IS_DISABLED === 'true') {
    return;
  }

  // In this case, SRE is using the environment name for the telemetryDbName
  const appEnvironment = isDevMode ? 'local' : appConfig?.environment;
  const sentryApiDsn = sentryApiDsnEnv || appConfig?.SentryApiDsn || '';
  const sentryTracesSampleRate = appConfig?.SentryTracesSampleRate || 1.0;

  init({
    dsn: sentryApiDsn,
    integrations: [browserTracingIntegration(), moduleMetadataIntegration()],
    transport,
    beforeSend: (event: ErrorEvent) => {
      // @ts-ignore this is legit, not sure why it's erroring out
      if (event?.exception?.values?.[0].stacktrace.frames) {
        const frames = event.exception.values[0].stacktrace.frames;
        // Find the last frame with module metadata containing a DSN
        const routeTo = frames
          .filter((frame) => frame.module_metadata && frame.module_metadata.dsn)
          .map((v) => v.module_metadata)
          .slice(-1); // using top frame only - you may want to customize this according to your needs

        if (routeTo.length) {
          event.extra = {
            ...event.extra,
            [EXTRA_KEY]: routeTo,
          };
        }
      }

      return event;
    },
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: sentryTracesSampleRate,
    normalizeDepth: 10, // Suggested for Redux depth
    environment: appEnvironment,
  });
};
