export const RUN_PROBABILISTIC_ANALYSIS = 'runProbabilisticAnalysis';
export const RUN_SCORING_ANALYSIS = 'runScoringAnalysis';
export const RUN_SCENARIO_ANALYSIS = 'runScenarioAnalysis';
export const PROBABILISTIC = 'probabilistic';
export const SCENARIO = 'scenario';
export const SCORING = 'SCORING';
export const PROBABILISTIC_ANALYSIS = 'Probabilistic Analysis';
export const SCENARIO_ANALYSIS = 'Scenario Analysis';
export const SCORING_ANALYSIS = 'Scoring Analysis';
export const DISABLE_ANALYSIS_BASED_ON_INSURED_COUNT = 30;

export const SUCCESS = 'SUCCESS';
export const ERROR = 'ERROR';
export const WARNING = 'WARNING';
export const INFO = 'INFO';
export const DEFAULT = 'DEFAULT';
export const SELECT = 'SELECT';
export const DESELECT = 'DESELECT';

export const VIEW = 'VIEW';
export const RENAME = 'RENAME';
export const DELETE = 'DELETE';
export const COMPARE = 'COMPARE';
export const SHARE = 'SHARE';
export const REFERENCE = 'REFERENCE';
export const REMOVE = 'REMOVE';

export const START = 'START';
export const END = 'END';
export const BEFORE = 'BEFORE';
export const AFTER = 'AFTER';

export const WAIT_TIME_NOTIFICATION_DISPLAY = 3000;

export const USER_ROLE_ACTUARY = 'ROLE_ACTUARY';
export const USER_ROLE_ADMIN = 'ROLE_ADMINISTRATOR';
export const USER_ROLE_CUSTOMER = 'ROLE_CUSTOMER';
export const USER_ROLE_PORTFOLIOMANAGER = 'ROLE_PORTFOLIOMANAGER';
export const USER_ROLE_PREMIUMUSER = 'ROLE_PREMIUMUSER';
export const USER_ROLE_UNDERWRITER = 'ROLE_UNDERWRITER';
export const USER_ROLES = {
  1: USER_ROLE_ADMIN,
  2: USER_ROLE_UNDERWRITER,
  3: USER_ROLE_ACTUARY,
  4: USER_ROLE_CUSTOMER,
  5: USER_ROLE_PREMIUMUSER,
  6: USER_ROLE_PORTFOLIOMANAGER,
};

export const COMPANY_FILTERS = [
  { matchedCompanies: null, text: 'All Companies' },
  { matchedCompanies: true, text: 'Companies Matched' },
  { matchedCompanies: false, text: 'Companies Not Matched' },
];

export const PORTFOLIO_TYPES = {
  ALL: 'all',
  COMPANIES_MATCHED: 'companies_matched',
  COMPANIES_NOT_MATCHED: 'companies_not_matched',
};

export const RESULT_SUB_TYPE_GROSS = 'Gross';
export const RESULT_SUB_TYPE_NET = 'Net';
export const RESULT_SUB_TYPE_CEDED = 'Ceded';

export const SUMMARY_STATISTICS_TAB_CV = 'CV';
export const SUMMARY_STATISTICS_TAB_STDAL = 'STDAL';
export const SUMMARY_STATISTICS_TAB_AAL = 'AAL';

export const EXCEEDANCE_PROBABILITY_METRICS_TAB_OEP = 'OEP';
export const EXCEEDANCE_PROBABILITY_METRICS_TAB_AEP = 'AEP';
export const EXCEEDANCE_PROBABILITY_METRICS_TAB_TCE = 'TCE';

export const SIM_LOSSES_BY_PORTFOLIO = 'SimLossesByPortfolio.csv';
export const SIM_LOSSES_BY_PORTFOLIO_DETAILED = 'SimLossesByPortfolioDetailed.csv';
export const SIM_LOSSES_BY_COMPANY = 'SimLossesByCompany.csv';
export const SIM_LOSSES_BY_COMPANY_YLT = 'SimLossesByCompanyYLT.zip';
export const SIM_LOSSES_BY_SCENARIO = 'SimLossesByScenario.csv';
export const SIM_LOSSES_BY_CLAIM_COUNT = 'SimLossesByClaimCount.csv';
export const LOSS_METRICS_CSV = 'LossMetrics.csv';
export const LOSS_METRICS_XLSX = 'LossMetrics.xlsx';
export const SCORING_REPORT_CSV = 'scoringReportCsv';
export const ALL_REPORTS = 'allReports';

export const BY_PORTFOLIO = 'By Portfolio';
export const BY_PORTFOLIO_DETAILED = 'By Portfolio (Detailed)';
export const BY_COMPANY = 'By Company';
export const BY_COMPANY_YLT = 'By Company YLT';
export const BY_SCENARIO = 'By Scenario';
export const BY_CLAIM_COUNT = 'By Claim Count';

export const TAB_COMPANY_INFORMATION = 'Company Information';
export const TAB_COMPANY_DEPENDENCIES = 'Company —> SPoF';
export const TAB_SPOF_DEPENDENCIES = 'SPoF —> Company';
export const TAB_PORTFOLIO = 'Portfolio';
export const TAB_ANALYSIS_RESULTS = 'Analysis Results';
export const TAB_SPOF = 'SPoF Dependencies';

export const TAB_RESULTS_BY_PORTFOLIO = 'Results By Portfolio';
export const TAB_SIMULATION_YEAR = 'Simulation Year';
export const TAB_INDUSTRY = 'Industry';
export const TAB_COMPANY_SIZE = 'Company Size';
export const TAB_GEOGRAPHY = 'Geography';
export const TAB_CONTRACT_TYPE = 'Contract Type';
export const TAB_CUSTOM_ID_1 = 'Custom ID 1';
export const TAB_CUSTOM_ID_2 = 'Custom ID 2';
export const TAB_COMPANY = 'Company';
export const TAB_SCENARIO = 'Scenario';
export const TAB_REINSURANCE_TREATIES = 'Reinsurance Treaties';
export const TAB_2D_INDUSTRY_SIZE = '2D - Industry/Size';
export const TAB_2D_INDUSTRY_REGION = '2D - Industry/Region';
export const TAB_2D_SIZE_REGION = '2D - Size/Region';
export const TAB_ANALYSIS_SETTINGS = 'Analysis Settings';
export const TAB_SCENARIO_RESULTS = 'Results';

export const TAB_COUNT_CHART_VIEW = 'Count Chart View';
export const TAB_LIMIT_CHART_VIEW = 'Limit Chart View';
export const TAB_LIST_VIEW = 'List View';

export const TABLE_ALL_COMPANIES = 'TABLE_ALL_COMPANIES';
export const TABLE_COMPANIES_MATCHED = 'TABLE_COMPANIES_MATCHED';
export const TABLE_COMPANIES_NOT_MATCHED = 'TABLE_COMPANIES_NOT_MATCHED';

export const MAX_NUMBER_RESULTS_PER_CALL_SPOF = 20;
export const MAX_NUMBER_RESULTS_PER_CALL_SPOF_VULNERABILITY = 89;
export const SORT_ORDER_ASC = 'asc';
export const SORT_ORDER_DSC = 'dsc';
export const DELAY_API_CALL = 1000;

// Analysis Result tabNames
export const RESULTS_BY_PORTFOLIO_TAB = 'Results By Portfolio';
export const ANALYSIS_SETTINGS_TAB = 'Analysis Settings';
export const SCENARIO_TAB = 'Scenario';
export const TWO_D_INDUSTRY_SIZE_TAB = '2D - Industry/Size';
export const TWO_D_REGION_SIZE_TAB = '2D - Size/Region';
export const TWO_D_INDUSTRY_REGION_TAB = '2D - Industry/Region';
export const COMPANY_TAB = 'Company';
export const COMPANY_SIZE_TAB = 'Company Size';
export const GEOGRAPHY_TAB = 'Geography';
export const CONTRACT_TYPE_TAB = 'Contract Type';
export const INDUSTRY_TAB = 'Industry';
export const SIMULATION_YEAR_TAB = 'Simulation Year';
export const CUSTOM_ID_1_TAB = 'Custom ID 1';
export const CUSTOM_ID_2_TAB = 'Custom ID 2';
export const REINSURANCE_TREATIES_TAB = 'Reinsurance Treaties';

export const SPOF_METRICS = 'spof_metrics';
export const SPOF_DEPENDENCIES = 'spof_dependencies';
export const SPOF_DETAILS = 'spof_details';
export const SPOF_METRIC_SORT_KEY = {
  spof_name: 'name',
  market_share_raw: 'marketShare',
  security_score: 'securityScore',
  security_score_rank: 'securityScoreRank',
  exposure_score: 'exposureScore',
  exposure_score_rank: 'exposureScoreRank',
  expert_score: 'expertScore',
  expert_score_rank: 'expertScoreRank',
  non_uniform_probabilistic: 'probabilistic',
  market_share_rank: 'marketShareRank',
  non_uniform_rank: 'rank',
  historical_breach: 'numOfBreaches',
  latest_breach: 'latestBreach',
  numOfOutages: 'numOfOutages',
  latestOutageStart: 'latest',
  averagePureOutage: 'duration',
  longestOutageDuration: 'longest',
  longestOutageStart: 'dateOfLongest',
  availabilityPure: 'availability',
  vulnerability: 'vulnerabilities',
};
export const SPOF_METRICS_NAME_MAPPING_BY_KEY = {
  spof_name: 'SPoF',
  market_share_raw: 'Market Share',
  market_share_rank: 'Market Share Rank',
  non_uniform_rank: 'Likelihood Rank',
  historical_breach: '# of Historical Breaches',
  latest_breach: 'Latest Historical Breach',
  vulnerability: '# of Critical Vulnerabilities',
  expert_score: 'Expert Score',
  expert_score_rank: 'Expert Rank',
  security_score: 'Security Score',
  security_score_rank: 'Security Rank',
  // exposure_rank: 'Exposuree Rank',
  exposure_score: 'Exposure Score',
  exposure_score_rank: 'Exposure Rank',
  non_uniform_probabilistic: 'Likelihood of Attack/Outage',
  // provider: 'Provider',
  // attentionOnDarkWeb: 'Attention on Dark Web',
  numOfOutages: '# of Outages',
  averagePureOutage: 'Avg Outage Duration',
  longestOutageDuration: 'Longest Outage Duration',
  longestOutageStart: 'Longest Outage',
  latestOutageStart: 'Latest Outage',
  availabilityPure: 'Availability',
};
export const TOOLTIP_CONTENT = {
  exposure_score:
    'A 0-100 score for the SPoF’s attractiveness to attackers and attack surface (higher exposure leads to more risk)',
  security_score:
    'A 0-100 score for the SPoF’s defenses vs. attackers (higher security leads to less risk)',
  expert_score:
    'A 0-100 score to adjust the SPoF’s overall risk, reflecting judgment on factors not picked up by other metrics (0-33 = lowered risk, 34-66 = minimal impact, 67-100 = heightened risk)',
  market_share_raw: 'How much of the market a SPoF has within its Type',
  non_uniform_probabilistic:
    'Given an incident happens to this SpoF Type, the chance that this SPoF is the one affected. A function of Security Score, Exposure Score, Market Share, and Expert Score.',
  availabilityPure: 'What percent of the time has the service been available.',
};
export const SCORES_TABLE_HEADERS = [
  {
    key: 'spof_name',
    name: 'SPoF',
    sortable: true,
  },
  {
    key: 'exposure_score',
    name: SPOF_METRICS_NAME_MAPPING_BY_KEY['exposure_score'],
    sortable: true,
    filter: TOOLTIP_CONTENT['exposure_score'],
  },
  {
    key: 'security_score',
    name: SPOF_METRICS_NAME_MAPPING_BY_KEY['security_score'],
    sortable: true,
    filter: TOOLTIP_CONTENT['security_score'],
  },
  {
    key: 'expert_score',
    name: SPOF_METRICS_NAME_MAPPING_BY_KEY['expert_score'],
    sortable: true,
    filter: TOOLTIP_CONTENT['expert_score'],
  },
  {
    key: 'market_share_raw',
    name: SPOF_METRICS_NAME_MAPPING_BY_KEY['market_share_raw'],
    sortable: true,
    filter: TOOLTIP_CONTENT['market_share_raw'],
  },
  {
    key: 'non_uniform_probabilistic',
    name: 'Likelihood',
    sortable: true,
    filter: TOOLTIP_CONTENT['non_uniform_probabilistic'],
  },
];
export const OUTAGES_TABLE_HEADERS = [
  {
    key: 'spof_name',
    name: 'SPoF',
    sortable: true,
  },
  {
    key: 'numOfOutages',
    name: SPOF_METRICS_NAME_MAPPING_BY_KEY['numOfOutages'],
    sortable: true,
  },
  {
    key: 'latestOutageStart',
    name: 'Latest',
    sortable: true,
  },
  {
    key: 'averagePureOutage',
    name: 'Avg. Duration',
    sortable: true,
  },
  {
    key: 'longestOutageDuration',
    name: 'Longest',
    sortable: true,
  },
  {
    key: 'longestOutageStart',
    name: 'Date of Longest',
    sortable: true,
  },
  {
    key: 'availabilityPure',
    name: SPOF_METRICS_NAME_MAPPING_BY_KEY['availabilityPure'],
    sortable: true,
    filter: TOOLTIP_CONTENT['availabilityPure'],
  },
];
export const BREACHES_TABLE_HEADERS = [
  {
    key: 'spof_name',
    name: 'SPoF',
    sortable: true,
  },
  {
    key: 'historical_breach',
    name: SPOF_METRICS_NAME_MAPPING_BY_KEY['historical_breach'],
    sortable: true,
  },
  {
    key: 'latest_breach',
    name: SPOF_METRICS_NAME_MAPPING_BY_KEY['latest_breach'],
    sortable: true,
  },
];
export const VULNERABILITIES_TABLE_HEADERS = [
  {
    key: 'spof_name',
    name: 'SPoF',
    sortable: true,
  },
  {
    key: 'vulnerability',
    name: SPOF_METRICS_NAME_MAPPING_BY_KEY['vulnerability'],
    sortable: true,
  },
  {
    key: 'none',
    name: '',
    sortable: false,
  },
];
export const SPOF_METRICS_SUBNAME_MAPPING_BY_KEY = {
  numOfOutages: 'Past 12 Months',
  averagePureOutage: 'Past 12 Months',
  longestOutageStart: 'Past 12 Months',
  latestOutageStart: 'Past 12 Months',
  availabilityPure: 'Past 12 Months',
};
export const SPOF_TYPE = {
  All: 'all',
  Modeled: 'modeled',
  NonModeled: 'non-modeled',
};

export const SPOF_TYPES = [
  { id: 'all', displayName: 'All SPoFs' },
  { id: 'modeled', displayName: 'Modeled SPoFs' },
  { id: 'non-modeled', displayName: 'Non-modeled SPoFs' },
];
export const SPOF_TYPES_INFORMATION = {
  all: {
    id: 'all',
    type: 'modeled',
    name: 'all',
    displayName: 'All SPoFs',
  },
  modeled: {
    id: 'modeled',
    type: 'modeled',
    name: 'Modeled',
    displayName: 'Modeled SPoFs',
  },
  'non-modeled': {
    id: 'non-modeled',
    type: 'modeled',
    name: 'Non-modeled',
    displayName: 'Non-modeled SPoFs',
  },
};
export const SPOF_METRICS_FIXED_VIEW_COLUMN_KEYS = [
  'spof_name',
  'market_share_raw',
  'market_share_rank',
];
export const SPOF_METRICS_IGNORED_SPOF_DETAILS_KEYS = [
  'spof_id',
  'type_id',
  'spof_type',
  'latestOutageStart',
  'longestOutageStart',
];
export const NON_MODELED_SPOF_METRICS_VIEW_COLUMN_KEYS = [
  'spof_name',
  'exposure_score',
  'exposure_score_rank',
];
// export const NON_MODELED_SPOF_METRICS_VIEW_COLUMN_KEYS = ['spof_name', 'exposure_score', 'exposure_score_rank', 'security_score', 'security_score_rank'];
export const NON_MODELED_SPOF_METRICS_IGNORED_SPOF_DETAILS_KEYS = [
  'spof_id',
  'type_id',
  'spof_type',
  'latestOutageStart',
  'longestOutageStart',
  'availabilityPure',
  'longestOutageDuration',
  'averagePureOutage',
  'numOfOutages',
  'non_uniform_probabilistic',
  'expert_score_rank',
  'expert_score',
  'non_uniform_rank',
  'market_share_rank',
  'market_share_raw',
];
export const ANALYSIS_REPORT_NAME_MAPPING_BY_KEY = {
  analysisName: 'Analysis Name',
  status: 'Status',
  portfolioName: 'Portfolio Name',
  analysisType: 'Analysis Type',
  analysisSettings: 'Analysis Settings',
  analysisTime: 'Time of Run',
};
export const MAX_NUMBER_RESULTS_PER_CALL_ANALYSIS_REPORTS = 20;

export const ANALYSIS_TAGS_FULL_NAME_MAPPING = {
  AAL: 'Average Annual Loss',
  ['SD-AL']: 'Standard Deviation of Annual Loss',
  CV: 'Coefficient of Variation',
  AEP: 'Aggregate Exceedance Probability',
  OEP: 'Occurrence Exceedance Probability',
};

export const ANALYSIS_TYPE_NAME_KEY_MAPPING = {
  Industry: 'industry',
  ContractType: 'contractType',
  Geography: 'geography',
  Size: 'size',
  Portfolio: 'portfolio',
};

export const DEPENDENCIES_VIEW_SUBJECT =
  'Select portfolio to view SPoF dependencies. You can change SPoF within the selected SPoF type.';
export const NO_DEPENDENCIES_WITHIN_SELECTED_SPOF_NAME_PORTFOLIO =
  'No dependencies available for selected SPoF / SPoF type and portfolio. Try a different portfolio/ provider or go back to SPoF Metrics to select a different SPoF type.';
export const HELP_MESSAGE_IN_DEPENDENCIES_PAGE = [
  'If you want  more flexibility, go to',
  'Portfolio Manager prefiltered view',
];

export const UNKNOWN = 'unknown';
export const EMPTY = 'empty';

export const ADD_PORTFOLIO = 'ADD_PORTFOLIO';
export const VALIDATE_PORTFOLIO = 'VALIDATE_PORTFOLIO';
export const PORTFOLIO_DATA_FILE = 'portfolioDataFile';
export const PORTFOLIO_DESC = 'portfolioDesc';
export const PORTFOLIO_NAME = 'portfolioName';

export const SCENARIOS_VIEW = 'SCENARIOS_VIEW';
export const CUSTOMIZE_SETTINGS_VIEW = 'CUSTOMIZE_SETTINGS_VIEW';
export const SPOF_VIEW = 'SPOF_VIEW';
export const ADDITIONAL_SETTINGS_VIEW = 'ADDITIONAL_SETTINGS_VIEW';
export const AGGREGATE_COST_COMPONENTS_VIEW = 'AGGREGATE_COST_COMPONENTS_VIEW';
export const SELECTION_VERIFICATION_VIEW = 'SELECTION_VERIFICATION_VIEW';

export const LENGTH_LESS_THAN_FOUR = 'LENGTH_LESS_THAN_FOUR';
export const LENGTH_GREATER_THAN_THIRTY = 'LENGTH_GREATER_THAN_THIRTY';
export const LENGTH_GREATER_THAN_128 = 'LENGTH_GREATER_THAN_128';
export const NO_SPECIAL_CHARS = 'NO_SPECIAL_CHARS';
export const NO_LEADING_SPACES = 'NO_LEADING_SPACES';
export const NO_TRAILING_SPACES = 'NO_TRAILING_SPACES';
export const NO_DUPLICATE_NAME = 'NO_DUPLICATE_NAME';
export const LENGTH_GREATER_THAN_NINETY_NINE = 'LENGTH_GREATER_THAN_NINETY_NINE';

export const ERROR_TOO_MANY_ACCOUNTS = 'ERROR_TOO_MANY_ACCOUNTS';
export const ERROR_COLUMNS = 'ERROR_COLUMNS';
export const ERROR_PARSE = 'ERROR_PARSE';
export const PORTFOLIO_ERROR_DEFINITIONS = {
  Duplicate: 'DUPLICATE COLUMN(S)',
  Missing: 'MISSING COLUMN(S)',
  Order: 'COLUMN(S) IN THE WRONG ORDER',
  Typo: 'UNKNOWN COLUMN(S)',
};

export const MAX_NUMBER_ACCOUNTS_PER_CALL_PORTFOLIO = 100;
export const MAX_NUMBER_PORTFOLIOS_PER_CALL = 20;
export const STATUS_POLLING_DELAY = 5000;

export const PORTFOLIOS_ALL = 'PORTFOLIOS_ALL';
export const PORTFOLIOS_USER = 'PORTFOLIOS_USER';
export const PORTFOLIOS_SHARED = 'PORTFOLIOS_SHARED';
export const PORTFOLIOS_IED = 'PORTFOLIOS_IED';

export const REPORTS_ALL = 'REPORTS_ALL';
export const REPORTS_USER = 'REPORTS_USER';
export const REPORTS_SHARED = 'REPORTS_SHARED';

export const IMPORT_TAB_SPOF = 'spof';
export const IMPORT_TAB_SPOF_INFO = '';
export const IMPORT_TAB_REINSURANCE = 'reinsurance';
export const IMPORT_TAB_REINSURANCE_INFO =
  'Add and manage reinsurance treaties and their terms & conditions. Be sure to properly enter corresponding information into your Portfolio Import Data Schema to utilize treaties.';

export const METRICS_SCORES = 'Scores';
export const METRICS_OUTAGES = 'Outages';
export const METRICS_BREACHES = 'Breaches';
export const METRICS_VULNERABILITIES = 'Vulnerabilities';

export const METRICS_TABS = ['Scores', 'Outages', 'Breaches', 'Vulnerabilities'];

export const MAX_NUMBER_RESULTS_PER_CALL_ACCOUNTS = 20;
export const IGNORE_COLUMNS_FOR_COMPANY_INFO_TABLE_DISPLAY = {
  0: true,
  1: true,
};

export const TREATY_FIELDS = {
  Code: 'CODE',
  Reinsurance: 'REINSURANCE',
  Ceded: 'CEDED',
  LayerParticipation: 'LAYER_PARTICIPATION',
  Limit: 'LIMIT',
  RiskLimit: 'RISK_LIMIT',
  RiskAttachmentPoint: 'RISK_ATTACHMENT_POINT',
};
export const TREATY_ACTIONS = {
  EDIT: 'TREATY_EDIT',
  DELETE: 'TREATY_DELETE',
};

export const ANALYSIS_OPTION_TYPE = {
  GROSS: 'Gross',
  NET: 'Net',
  CEDED: 'Ceded',
};

export const ANALYSIS_OPTIONS = [
  ANALYSIS_OPTION_TYPE.GROSS,
  ANALYSIS_OPTION_TYPE.NET,
  ANALYSIS_OPTION_TYPE.CEDED,
];

export const MAX_DIGITS_ALLOWED = 19; // Long supports until 19 digits
export const MAX_CURRENCY_ALLOWED = 9000000000000000000;

export const APPLICATION_TYPES = {
  ACCOUNT_MANAGER: 'AMRegularUser',
  PORTFOLIO_MANAGER: 'PMRegularUser',
  ADMIN: 'Admin',
};

export const ADDON_TYPES = {
  IED: 'PM-IED',
  SPOF: 'SPOF',
  ATTRITIONAL: 'ALOSS',
  ODS: 'AM-ODS',
  YLT: 'AM-YLT',
};

export const TENANT_PRODUCTS = {
  PM: 'PM',
  AM: 'AM',
  BM: 'BM',
  CC: 'CC',
};

export const LICENSES = {
  AMRegularUser: 'Account Manager',
  PMRegularUser: 'Portfolio Manager',
  XMRegularUser: 'Exposure Manager',
  SPOF: 'SPoF Intelligence',
  ALOSS: 'Attritional Loss',
};

export const ROLES = {
  AM_REGULAR_USER: 'AMRegularUser',
  AM_ADMIN: 'AMAdmin',
  PM_REGULAR_USER: 'PMRegularUser',
  PM_ADMIN: 'PMAdmin',
  BM_REGULAR_USER: 'BMRegularUser',
  BM_ADMIN: 'BMAdmin',
  BM_MANAGER: 'BMManager',
  XM_REGULAR_USER: 'XMRegularUser',
  XM_ADMIN: 'XMAdmin',
  XM_MANAGER: 'XMManager',
  API_ADMIN: 'APIAdmin',
  API_USER: 'APIUser',
  ADMIN: 'Admin',
  STAFF_USER: 'Staff',
};

export const NOTIFICATION_MESSAGES = {
  CANNOT_GET_PORTFOLIO_DATA: "Can't get portfolio data. Please select a different portfolio.",
  COULD_NOT_DELETE_THE_ANALYSIS: 'Could not delete the analysis',
  DEPENDENCIES_NOT_FOUND: 'Dependencies Not Found',
  ERROR_GETTING_ANALYSIS_RESULTS_SETTINGS: 'Error getting analysis results settings.',
  ERROR_GETTING_ANALYSIS_RESULTS: 'Error getting analysis results',
  EXTERNAL_PAGES_ARE_CURRENTLY_UNAVAILABLE: 'External pages are currently unavailable.',
  FAILED_TO_LOAD_EVENT_FAMILIES_DATA: 'Failed to load Event Families data!',
  FAILED_TO_LOAD_POLICY_WORDINGS_DATA: 'Failed to load Policy Wordings data.',
  IT_MIGHT_TAKE_FEW_MINUTES_FOR_THE_ANALYSIS:
    'It might take few minutes for the analysis to show up in the list.',
  NO_COMPANIES_TO_DISPLAY:
    'There are no impacted companies to display SPoF and Company dependencies.',
  PLEASE_CHANGE_THE_PORTFOLIO_NAME:
    'Please change the name as a portfolio with that name already exists.',
  PLEASE_RETRY_LATER: 'Please retry later.',
  PLEASE_RETRY: 'Please retry.',
  PLEASE_SELECT_ONLY_ANALYSIS_THAT_IS_OWNED_BY_YOU:
    'Please select only analysis that is owned by you.',
  PLEASE_SELECT_ONLY_PORTFOLIO_THAT_IS_OWNED_BY_YOU:
    'Please select only portfolio that is owned by you.',
  PLEASE_SELECT_THE_FILE_AGAIN: 'Please select the file again.',
  PLEASE_TRY_AGAIN_LATER: 'Please try again later.',
  PLEASE_TRY_AGAIN: 'Please try again.',
  SOMETHING_BAD_HAPPENED: 'Something bad happened. Reload the page!',
  SOMETHING_WENT_WRONG: 'Something went wrong please try again later.',
  SUCCESS_REVERT: 'Successfully reverted',
  THE_PORTFOLIO_IS_STILL_UPLOADING_SPOF:
    'The portfolio is still uploading and processing for SPOF. Please try again later.',
  THERE_ARE_NO_IMPACTED_COMPANIES:
    'There are no impacted companies to display SPoF and Company dependencies.',
  THERE_HAS_BEEN_AN_ERROR: 'There has been an error, please contact your administrator.',
  UNABLE_TO_ADD_PORTFOLIO: 'Unable to add portfolio',
  UNABLE_TO_COMPARE_ANALYSIS: 'Unable to compare analyses.',
  UNABLE_TO_CONNECT: 'Unable to connect',
  UNABLE_TO_DOWNLOAD_COMPANY_YLT: 'Unable to download company ylt',
  UNABLE_TO_DOWNLOAD_DEPENDENCIES: 'Unable to download dependencies',
  UNABLE_TO_DOWNLOAD_PORTFOLIO: 'Unable to download portfolio',
  UNABLE_TO_DOWNLOAD_REPORT: 'Unable to download report.',
  UNABLE_TO_DOWNLOAD_TEMPLATE: 'Unable to download template',
  UNABLE_TO_FETCH_PORTFOLIO_DETAILS: 'Unable to fetch portfolio details',
  UNABLE_TO_FETCH_USER_GROUPS: 'Unable to fetch user groups!',
  UNABLE_TO_GET_TARGETED_SPOF: 'Unable to get targeted SPoF',
  UNABLE_TO_IMPORT_PORTFOLIO: 'Unable to import portfolio',
  UNABLE_TO_LOAD_COMPANY_INFORMATION: 'Unable to load company information',
  UNABLE_TO_LOAD_DATA: 'Unable to load data',
  UNABLE_TO_REMOVE_ANALYSES: 'Unable to remove analyses',
  UNABLE_TO_REMOVE_PORTFOLIO: 'Unable to remove portfolio',
  UNABLE_TO_RETRIEVE_DATA: 'Unable to retrieve data',
  UNABLE_TO_RETRIEVE_MORE_VULNERABILITY_DATA: 'Unable to retrieve more vulnerability data',
  UNABLE_TO_REVERT_TARGETED_SPOF: 'Unable to revert targeted SPoF',
  UNABLE_TO_REVERT: 'Unable to revert',
  UNABLE_TO_RUN_PROBABILISTIC: 'Unable to run Probabilistic Analysis',
  UNABLE_TO_RUN_SCENARIO: 'Unable to run Scenario Analysis',
  UNABLE_TO_RUN_SCORING: 'Unable to run Scoring Analysis',
  UNABLE_TO_SAVE_ANALYSIS_SETTINGS: 'Unable to save Analysis Settings',
  UNABLE_TO_SAVE: 'Unable to save',
  UNABLE_TO_SET_ANALYSIS_REFERENCE: 'Unable to set analysis as reference',
  UNABLE_TO_SHARE_ANALYSIS_THAT_IS_NOT_OWNED: 'Unable to share analysis that is not owned',
  UNABLE_TO_SHARE_ANALYSIS: 'Unable to share analysis (or analyses)',
  UNABLE_TO_SHARE_PORTFOLIO_THAT_IS_NOT_OWNED: 'Unable to share portfolio that is not owned',
  UNABLE_TO_SHARE_PORTFOLIO: 'Unable to share portfolio',
  UNABLE_TO_UPDATE_ANALYSIS_NAME: 'Unable to update analysis name',
  UNABLE_TO_UPDATE_PORTFOLIO_NAME: 'Unable to update portfolio name',
  UNABLE_TO_UPDATE_TARGETED_SPOF: 'Unable to update targeted SPoF',
  UNABLE_TO_UPLOAD_FILE: 'Unable to upload file',
  UNABLE_TO_UPLOAD_PORTFOLIO: 'Unable to upload portfolio',
  UNABLE_TO_VALIDATE_FILE: 'Unable to validate file',
  UNABLE_TO_VALIDATE_PORTFOLIO: 'Unable to validate portfolio',
  UNSAVED_ANALYSIS_SETTINGS_WILL_BE_LOST: 'Unsaved analysis settings will be lost!',
  USER_ADMIN_IS_CURRENTLY_UNAVAILABLE: 'User Admin is currently unavailable',
  YOU_SUCCESSFULLY_DELETED_THE_SCORING_ANALYSIS: 'You successfully deleted the scoring analysis.',
};

export const PORTFOLIO_VALIDATION_MESSAGES = {
  [LENGTH_LESS_THAN_FOUR]: 'Portfolio name should be at least 4 characters',
  [LENGTH_GREATER_THAN_THIRTY]: 'Portfolio name should be at most 30 characters',
  [NO_SPECIAL_CHARS]: 'Portfolio name should not contain special characters',
  [NO_LEADING_SPACES]: 'Portfolio name should not contain leading spaces',
  [NO_TRAILING_SPACES]: 'Portfolio name should not contain trailing spaces',
  [NO_DUPLICATE_NAME]: 'Portfolio with that name already exists',
  [LENGTH_GREATER_THAN_NINETY_NINE]: 'Filename exceeds 99 characters',
};

export const VALIDATION_RULES = {
  PORTFOLIO_DETAILS_RUN_ANALYSIS_SCORING: 'PORTFOLIO_DETAILS_RUN_ANALYSIS_SCORING',
  PORTFOLIO_DETAILS_SPOF_DEPENDENCIES: 'PORTFOLIO_DETAILS_SPOF_DEPENDENCIES',
  PORTFOLIOS_PAGE_RUN_SCORING_ANALYSIS: 'PORTFOLIOS_PAGE_RUN_SCORING_ANALYSIS',
};
