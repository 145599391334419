import React, { useEffect, useRef, useState } from 'react';
import { shellStore, ShellStoreApi, useErrorBoundary, LoadingSpinnerLg } from 'c2-common-ui';

interface AppModule {
  default: {
    mount: (
      container: HTMLElement,
      shellStoreApi: ShellStoreApi,
      signal: AbortSignal
    ) => Promise<void>;
    unmount: (container: HTMLElement) => Promise<void>;
  };
}

interface AppLoaderProps {
  loadComponent: () => Promise<AppModule>;
  condition?: () => boolean;
}

const AppLoader: React.FC<AppLoaderProps> = ({ loadComponent, condition }) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const abortControllerRef = useRef<AbortController | null>(null);
  const loadIdRef = useRef(0); // Ref to track the load sequence
  const [isAllowed, setIsAllowed] = useState(false);
  const [error, setError] = useState<unknown>(null);
  const { showBoundary } = useErrorBoundary();
  const [isMounting, setIsMounting] = useState(true);

  useEffect(() => {
    setIsMounting(true);

    loadIdRef.current += 1;
    const currentLoadId = loadIdRef.current;
    let cleanupFunction: () => Promise<void> | undefined;

    const checkAndLoadComponent = async () => {
      const abortController = new AbortController();
      abortControllerRef.current = abortController;

      const allowed = condition ? condition() : true;
      setIsAllowed(allowed);
      if (!allowed) return;

      try {
        const module = await loadComponent();
        if (module.default.mount && containerRef.current && !abortController.signal.aborted) {
          await module.default.mount(containerRef.current, shellStore, abortController.signal);
        }

        setIsMounting(false);

        cleanupFunction = async () => {
          if (module.default.unmount && containerRef.current) {
            try {
              await module.default.unmount(containerRef.current);
            } catch (err) {
              console.error('Error during unmounting child app:', err);
              // Ensure the error is related to the current load attempt
              if (loadIdRef.current === currentLoadId) {
                setError(err);
              }
            }
          }
        };
      } catch (err) {
        console.error('Error during mounting child app:', err);
        // Ensure the error is related to the current load attempt
        if (loadIdRef.current === currentLoadId) {
          setError(err);
        }
      }
    };

    checkAndLoadComponent().catch((err) => {
      console.error('Failed to load child app:', err);
      // Ensure the error is related to the current load attempt
      if (loadIdRef.current === currentLoadId) {
        setError(err);
      }
    });

    return () => {
      // Abort the mount if the component unmounts
      if (abortControllerRef.current) {
        abortControllerRef.current.abort('App unmounted');
      }
      if (cleanupFunction) {
        cleanupFunction();
      }
    };
  }, [loadComponent, condition]);

  useEffect(() => {
    if (error) {
      showBoundary();
    }
  }, [error, showBoundary]);

  if (!isAllowed) {
    return (
      <div>
        <h1>Insufficient Privileges</h1>
        <p>Your account does not have the required privileges to view this page.</p>
        <p>Please contact your administrator if you think this is an error.</p>
      </div>
    );
  }

  return (
    <>
      {isMounting && <LoadingSpinnerLg />}
      <div ref={containerRef} />
    </>
  );
};

export default AppLoader;
