import { useEffect, useState } from 'react';
import { RouterProvider } from 'react-router-dom';
import { appRouter } from '@/routing/appRouter';
import { LoadingSpinnerLg } from 'c2-common-ui';
import { initApplicationData, shouldLogin, startApp } from '@/index.helpers';

import './App.scss';

const App = () => {
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      init();
    }, 250);
  }, []);

  const init = async () => {
    await startApp();

    if (shouldLogin()) {
      window.location.href = '/staticViews/login.htm';
    } else {
      await initApplicationData();
      setIsReady(true);
    }
  };

  if (!isReady) {
    return (
      <div id="page-content-wrapper">
        <LoadingSpinnerLg />
      </div>
    );
  }

  return <RouterProvider router={appRouter} />;
};

export default App;
