import ReactDOM from 'react-dom';
import { Application } from 'c2-common-ui';
import { isInPdfContext } from './services/pdfService';
import { startPdfApp } from './index.helpers';
import App from './entrypoints/App';
import PdfApp from './entrypoints/PdfApp';
import 'c2-common-ui/dist/style.css';
import './styles/scss/fonts-regular.scss';
import './styles/scss/styles.scss';

async function initWebApp() {
  Application.Bootstrap.initSessionHandler();
  ReactDOM.render(<App />, document.getElementById('root'));
}

async function initPdfApp() {
  await startPdfApp();
  ReactDOM.render(<PdfApp />, document.getElementById('root'));
}

async function init() {
  try {
    if (isInPdfContext()) {
      await initPdfApp();
    } else {
      await initWebApp();
    }
  } catch (error) {
    console.error('Failed to initialize shell app:', error);
  }
}

init();
