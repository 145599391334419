import { createBrowserRouter, Navigate, RouteObject } from 'react-router-dom';
import { isUserAM, isUserPM, isUserXM } from '@/services/userInfoService';
import { ROUTES } from '@/scripts/utilities/constants';
import AppLoader from './components/AppLoader';
import RootRoute from './components/RootRoute';

// IMPORTANT: Ensure all session storage data such as roles and products are fully loaded before any condition checks.
// This is crucial to prevent race conditions, especially with dynamic access control based on session state.
// This reminder serves to caution against potential changes that may affect the loading sequence of these dependencies.

const RedirectToRole = () => {
  const roleToRouteMap = [
    { condition: isUserAM, route: ROUTES.SCORING_REPORTS },
    { condition: isUserPM, route: ROUTES.PORTFOLIOS },
    { condition: isUserXM, route: ROUTES.EXPOSURE_MANAGER_PORTFOLIOS },
  ];

  const matchedRoute = roleToRouteMap.find(({ condition }) => condition());

  return <Navigate to={matchedRoute ? matchedRoute.route : ROUTES.PORTFOLIOS} replace />;
};

export const appRoutes: RouteObject[] = [
  {
    path: '/',
    element: <RootRoute />,
    children: [
      {
        index: true,
        element: <RedirectToRole />,
      },
      {
        path: 'am/*',
        element: (
          <AppLoader condition={isUserAM} loadComponent={() => import('AccountManager/App')} />
        ),
      },
      {
        path: 'pm/*',
        element: (
          <AppLoader
            condition={() => isUserPM() || isUserXM()}
            loadComponent={() => import('PortfolioManager/App')}
          />
        ),
      },
      {
        path: 'xm/*',
        element: (
          <AppLoader condition={isUserXM} loadComponent={() => import('ExposureManager/App')} />
        ),
      },
    ],
  },
  {
    path: '*',
    element: <Navigate to="/" replace />,
  },
];

export const appRouter = createBrowserRouter(appRoutes);
