import { ShellStoreSync } from 'c2-common-ui';
import { useLocation } from 'react-router-dom';
import { appRouter } from '@/routing/appRouter';
import { ErrorBoundary } from '@/components/NotificationFactory';
import Layout from './Layout';

const RootRoute = () => {
  return (
    <ErrorBoundary>
      <ShellStoreSync useLocation={useLocation} navigate={appRouter.navigate} />
      <Layout />
    </ErrorBoundary>
  );
};

export default RootRoute;
