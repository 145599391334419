import { useLocation } from 'react-router-dom';
import { CMSProvider, Widget } from 'c2-cms-plugin';
import { APP_URL_PREFIX } from '@/scripts/utilities/constants';

import 'c2-cms-plugin/dist/index.css';
import 'simplebar/dist/simplebar.min.css';
import './cmsComponentOverrides.styles.scss';

const APP_TYPES = {
  AM: 'AM',
  PM: 'PM',
  XM: 'XM',
  UNKNOWN: 'Unknown',
} as const;

type AppType = (typeof APP_TYPES)[keyof typeof APP_TYPES];

const APP_PATH_MAPPING: Record<string, AppType> = {
  [APP_URL_PREFIX.AM]: APP_TYPES.AM,
  [APP_URL_PREFIX.PM]: APP_TYPES.PM,
  [APP_URL_PREFIX.XM]: APP_TYPES.XM,
};

const getAppFromPathname = (pathname: string): AppType => {
  const matchedPath = Object.keys(APP_PATH_MAPPING).find((prefix) => pathname.startsWith(prefix));
  return matchedPath ? APP_PATH_MAPPING[matchedPath] : APP_TYPES.UNKNOWN;
};

const CmsComponent = () => {
  const location = useLocation();
  const app = getAppFromPathname(location.pathname);

  if (app === APP_TYPES.UNKNOWN) {
    return null;
  }

  return (
    <div className="cms-widget-container">
      <CMSProvider
        key={app}
        // @ts-expect-error: 'XM' is not yet supported by c2-cms-plugin, to be removed once updated
        app={app}
        devMode={
          !!localStorage.getItem('devModeCyberCube') || location.pathname.includes('localhost')
        }
        enableNotifications
        api="http://localhost:1337"
        ui="http://localhost:3000"
      >
        {app === APP_TYPES.AM && <Widget />}
      </CMSProvider>
    </div>
  );
};

export default CmsComponent;
