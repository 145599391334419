import { Telemetry } from 'c2-common-ui';
import { telemetryVariables } from '@/constants/telemetryVariables';

/*
 Example of usage:

 1) Passing in the object. Note that it doesn't require sendMetric call.
  telemetryEvent({
    action: telemetryVariables.CLICK,
    component: telemetryVariables.BUTTON,
    feature: telemetryVariables.PORTFOLIOS_PAGE,
    event: telemetryVariables.CLICK_SEARCH_BY_COMPANY_NAME
  });

  2) Chaining function calls.
  Note that each chain returns the telemetryEvent instance so the order doesn't matter, except for sendMetric().

    a) passing variables in chained functions
    telemetryEvent()
      .action(telemetryVariables.CLICK)
      .component(telemetryVariables.BUTTON)
      .feature(telemetryVariables.PORTFOLIOS_PAGE)
      .event(telemetryVariables.CLICK_SEARCH_BY_COMPANY_NAME)
      .sendMetric();

    b) using shorthand methods like click() and button()
    telemetryEvent()
      .click()
      .button()
      .feature(telemetryVariables.PORTFOLIOS_PAGE)
      .event(telemetryVariables.CLICK_SEARCH_BY_COMPANY_NAME)
      .sendMetric();

    c) having event() method called last, if all variables are set, will trigger sendMetrics() automatically
       workes also with modalEvent() and eventWithSuffix()
     telemetryEvent()
      .click()
      .button()
      .feature(telemetryVariables.PORTFOLIOS_PAGE)
      .event(telemetryVariables.CLICK_SEARCH_BY_COMPANY_NAME)

*/

export interface TelemetryEvent {
  app?: TelemetryVariable;
  action?: TelemetryVariable;
  feature?: TelemetryVariable;
  component?: TelemetryVariable;
  event?: TelemetryVariable;
}

type TelemetryVariable = (typeof telemetryVariables)[keyof typeof telemetryVariables];

// function that generates new TelemetryEventService instance
export function telemetryPMEvent({ app, action, feature, component, event }: TelemetryEvent = {}) {
  return new TelemetryEventService({
    app: app || telemetryVariables.PM,
    action: action || telemetryVariables.CLICK,
    feature,
    component,
    event,
  });
}

export function telemetryAMEvent({ app, action, feature, component, event }: TelemetryEvent = {}) {
  return new TelemetryEventService({
    app: app || telemetryVariables.AM,
    action: action || telemetryVariables.CLICK,
    feature,
    component,
    event,
  });
}

class TelemetryEventService {
  private _app?: TelemetryVariable;
  private _action?: TelemetryVariable;
  private _feature?: TelemetryVariable;
  private _component?: TelemetryVariable;
  private _event?: TelemetryVariable;
  private isMetricSent: boolean = false;

  constructor({ app, action, feature, component, event }: TelemetryEvent = {}) {
    this._app = app;
    this._action = action;
    this._feature = feature;
    this._component = component;
    this._event = event;

    // if all data is provided in constructor, we can sendMetric right away
    if (this.isValid()) {
      this.sendMetric();
    }
  }

  isValid() {
    if (this._app && this._action && this._feature && this._component && this._event) {
      return true;
    }
    return false;
  }

  // just a short name for sendMetric
  send() {
    this.sendMetric();
  }

  sendMetric() {
    if (this.isMetricSent) {
      return;
    }
    if (this.isValid()) {
      Telemetry.addUIEventMetric({
        app: this._app,
        action: this._action,
        feature: this._feature,
        component: this._component,
        event: this._event,
      });
      this.isMetricSent = true;
    } else {
      console.error('Telemetry Event failed to log. Missing required params.');
    }
  }

  app(app: TelemetryVariable) {
    this._app = app;
    return this;
  }

  action(action: TelemetryVariable) {
    this._action = action;
    return this;
  }

  click() {
    return this.action(telemetryVariables.CLICK);
  }

  scroll() {
    return this.action(telemetryVariables.SCROLL);
  }

  hover() {
    return this.action(telemetryVariables.HOVER);
  }

  returnKey() {
    return this.action(telemetryVariables.RETURN_KEY);
  }

  view() {
    return this.action(telemetryVariables.VIEW);
  }

  component(component: TelemetryVariable) {
    this._component = component;
    return this;
  }

  button() {
    this.component(telemetryVariables.BUTTON);
    // if component is a button, it's probably a click action
    if (!this._action) {
      this.click();
    }
    return this;
  }

  link() {
    this.component(telemetryVariables.LINK);
    // if component is a link, it's probably a click action
    if (!this._action) {
      this.click();
    }
    return this;
  }

  input() {
    return this.component(telemetryVariables.INPUT);
  }

  tooltip() {
    return this.component(telemetryVariables.TOOLTIP);
  }

  filterTab() {
    return this.component(telemetryVariables.FILTERTAB);
  }

  pageTab() {
    return this.component(telemetryVariables.PAGETAB);
  }

  dropdownItem() {
    return this.component(telemetryVariables.DROPDOWNITEM);
  }

  checkbox() {
    return this.component(telemetryVariables.CHECKBOX);
  }

  menuItem() {
    return this.component(telemetryVariables.MENUITEM);
  }

  table() {
    return this.component(telemetryVariables.TABLE);
  }

  tableRow() {
    return this.component(telemetryVariables.TABLEROW);
  }

  tableHeader() {
    return this.component(telemetryVariables.TABLEHEADER);
  }

  typeahead() {
    return this.component(telemetryVariables.TYPEAHEAD);
  }

  page() {
    return this.component(telemetryVariables.PAGE);
  }

  feature(feature: TelemetryVariable) {
    this._feature = feature;
    return this;
  }

  event(event: TelemetryVariable) {
    this._event = event;
    this.sendMetric();
    return this;
  }

  // event that concats 'open' or 'close" (based on the second argument) at the end of the event string
  modalEvent(event: TelemetryVariable, open: boolean) {
    let e = event;

    if (typeof open === 'boolean') {
      if (open) {
        e += '_open';
      } else {
        e += '_close';
      }
    }
    return this.event(e as TelemetryVariable);
  }

  // event that concats second argument (lower case) at the end of the event string
  eventWithSuffix(event: TelemetryVariable, suffix: string) {
    let e = event;

    if (typeof suffix === 'string') {
      e += '_' + suffix.replaceAll(' ', '_').toLowerCase();
    }
    return this.event(e as TelemetryVariable);
  }
}
