import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { SideNavComponentV2, SideNavData } from 'c2-common-ui';
import { userInfo } from '@/services/userInfoService';
import menuItems from '@/scripts/utilities/menuItems';
import { fetchDataSideNav, handleClickItem, handleOpenExternalUrl } from './SideNav.utils';

const SideNav = SideNavComponentV2.SideNav as React.ElementType;

const SideNavComponent: React.FC = () => {
  const [sideNavData, setSideNavData] = useState({} as SideNavData);
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    fetchDataSideNav({
      setIsLoading,
      setData: setSideNavData,
      menuItems,
      userInfo,
    });
  }, []);

  return (
    <SideNav
      className=""
      currentPathname={location.pathname}
      data={sideNavData}
      loading={isLoading}
      handleClickItem={(url: string) => handleClickItem(navigate, url)}
      handleOpenExternalUrl={handleOpenExternalUrl}
    />
  );
};

export default SideNavComponent;
