declare global {
  interface Window {
    completeRendering?: () => void;
    abortRendering?: () => void;
    retrieveDataForPdf?: () => Promise<unknown>;
  }
}

export function isInPdfContext() {
  return !!(window.completeRendering && window.abortRendering && window.retrieveDataForPdf);
}
