export const telemetryVariables = {
  // app
  PM: 'PM',
  AM: 'AM',

  // actions
  CLICK: 'click',
  SCROLL: 'scroll',
  HOVER: 'hover',
  RETURN_KEY: 'return_key',
  VIEW: 'view',

  // features
  SIDE_NAV: 'sidenav',
  PORTFOLIOS_PAGE: 'portfolios_page',
  PORTFOLIO_DETAILS: 'portfolio_details',
  PORTFOLIO_SCORING_DETAILS_PAGE: 'portfolio_scoring_details_page',
  GLOBAL_SEARCH_: 'global_search',
  ADD_PORTFOLIO_MODAL: 'add_portfolio_modal',
  REMOVE_PORTFOLIO_MODAL: 'remove_portfolio_modal',
  RENAME_PORTFOLIO_MODAL: 'rename_portfolio_modal',
  SHARE_PORTFOLIO_MODAL: 'share_portfolio_modal',
  PROBABILISTIC_ANALYSIS_MODAL: 'probabilistic_analysis_modal',
  SCENARIO_ANALYSIS_MODAL: 'scenario_analysis_modal',
  SCORING_ANALYSIS_MODAL: 'scoring_analysis_modal',
  COMPANY_TO_SPOF_DEPENDENCIES: 'company_to_spof_dependencies',
  COMPANY_RESULTS_MODAL: 'company_results_modal',
  SPOF_TO_COMPANY_DEPENDENCIES: 'spof_to_company_dependencies',
  SPOF_RESULTS_MODAL: 'spof_results_modal',
  EDIT_SPOF_DEPENDENCIES_MODAL: 'edit_spof_dependencies_modal',
  COMPANY_INFORMATION: 'company_information',
  ANALYSIS_REPORTS_PAGE: 'analysis_reports_page',
  ANALYSIS_RESULTS_PAGE: 'analysis_results_page',
  SHARE_ANALYSIS_MODAL: 'share_analysis_modal',
  REFERENCE_ANALYSIS_MODAL: 'reference_analysis_modal',
  RENAME_ANALYSIS_MODAL: 'rename_analysis_modal',
  REMOVE_ANALYSIS_MODAL: 'remove_analysis_modal',
  COMPARE_ANALYSIS_MODAL: 'compare_analysis_modal',
  SPOF_METRICS_PAGE: 'spof_metrics_page',
  SPOF_DETAILS_PAGE: 'spof_details_page',
  IMPORT_MANAGEMENT_PAGE: 'import_management_page',
  PLATFORM_SETTINGS_PAGE: 'platform_settings_page',
  POLICY_WORDING_PAGE: 'policy_wording_page',

  // components
  BUTTON: 'button',
  INPUT: 'input',
  TOOLTIP: 'tooltip',
  FILTERTAB: 'filter_tab',
  PAGETAB: 'page_tab',
  DROPDOWNITEM: 'dropdown_item',
  CHECKBOX: 'checkbox',
  MENUITEM: 'menuitem',
  TABLE: 'table',
  TABLEROW: 'tablerow',
  TABLEHEADER: 'tableheader',
  LINK: 'link',
  TYPEAHEAD: 'typeahead',
  PAGE: 'page',

  // events
  // side menu
  SELECT_SIDE_NAVIGATION: 'select_side_menu_navigation',
  // portfolios view
  FILTER_REGION_ON_PORTFOLIO_SCORING_ANALYSIS_RESULTS:
    'filter_region_on_portfolio_scoring_analysis_results',
  FILTER_SIZE_ON_PORTFOLIO_SCORING_ANALYSIS_RESULTS:
    'filter_size_on_portfolio_scoring_analysis_results',
  FILTER_INDUSTRY_ON_PORTFOLIO_SCORING_ANALYSIS_RESULTS:
    'filter_industry_on_portfolio_scoring_analysis_results',
  VIEW_PORTFOLIOS_PAGE: 'view_portfolios_page',
  SET_PORTFOLIOS_TABLE_FILTER: 'set_portfolios_table_filter',
  CLICK_SEARCH_PORTFOLIO: 'click_search_portfolio',
  FETCH_PORTFOLIOS_ADDITIONAL_DATA: 'fetch_portfolios_additional_data',
  TOGGLE_ADD_PORTFOLIO_MODAL: 'toggle_add_portfolio_modal',
  TOGGLE_RUN_PROBABILISTIC_ANALYSIS_MODAL: 'toggle_run_probabilistic_analysis_modal',
  TOGGLE_RUN_SCENARIO_ANALYSIS_MODAL: 'toggle_run_scenario_analysis_modal',
  TOGGLE_RUN_SCORING_ANALYSIS_MODAL: 'toggle_run_scoring_analysis_modal',
  TOGGLE_REMOVE_PORTFOLIO_MODAL: 'toggle_remove_portfolio_modal',
  TOGGLE_RENAME_PORTFOLIO_MODAL: 'toggle_rename_portfolio_modal',
  TOGGLE_SHARE_PORTFOLIO_MODAL: 'toggle_share_portfolio_modal',
  SELECT_ALL_PORTFOLIOS: 'select_all_portfolios',
  DESELECT_ALL_PORTFOLIOS: 'deselect_all_portfolios',
  SELECT_PORTFOLIO: 'select_portfolio',
  DESELECT_PORTFOLIO: 'deselect_portfolio',
  SUBMIT_REMOVE_PORTFOLIO: 'submit_remove_portfolio',
  SUBMIT_RENAME_PORTFOLIO: 'submit_rename_portfolio',
  SORT_PORTFOLIOS: 'sort_portfolios',
  SELECT_INCLUDE_SHARE_ANALYSIS: 'select_include_share_analysis',
  DESELECT_INCLUDE_SHARE_ANALYSIS: 'deselect_include_share_analysis',
  SELECT_USER_TO_SHARE_PORTFOLIOS: 'select_user_to_share_portfolios',
  REMOVE_USER_TO_SHARE_PORTFOLIOS: 'remove_user_to_share_portfolios',
  REVIEW_SHARE_PORTFOLIOS: 'review_share_portfolios',
  GO_BACK_FROM_CONFIRM_SHARE_PORTFOLIOS: 'go_back_from_confirm_share_portfolios',
  SUBMIT_SHARE_PORTFOLIOS: 'submit_share_portfolios',
  // single portfolio view
  VIEW_PORTFOLIO_DETAILS_PAGE: 'view_portfolio_details_page',
  SET_ACTIVE_TAB_PORTFOLIO: 'set_active_tab_portfolio',
  SET_ACTIVE_TAB_SPOF_DEPENDENCIES_COMPANY_TO_SPOF:
    'set_active_tab_spof_dependencies_company_to_spof',
  SET_ACTIVE_TAB_SPOF_DEPENDENCIES_SPOF_TO_COMPANY:
    'set_active_tab_spof_dependencies_spof_to_company',
  SET_ACTIVE_TAB_COMPANY_INFORMATION: 'set_active_tab_company_information',
  SET_ACTIVE_TAB_ANALYSIS_RESULTS: 'set_active_tab_analysis_results',
  GO_BACK_FROM_PORTFOLIO_DETAILS: 'go_back_from_portfolio_details',
  DOWNLOAD_PORTFOLIO_ALL_COMPANIES: 'download_all_companies',
  DOWNLOAD_PORTFOLIO_COMPANIES_MATCHED_ONLY: 'download_portfolio_companies_matched_only',
  DOWNLOAD_PORTFOLIO_COMPANIES_NOT_MATCHED_ONLY: 'download_portfolio_companies_not_matched_only',
  DOWNLOAD_DEPENDENCIES_MODELED_DEPENDENCIES: 'download_dependencies_modeled_dependencies',
  DOWNLOAD_DEPENDENCIES_DETAILED_MODELED_DEPENDENCIES:
    'download_dependencies_detailed_modeled_dependencies',
  DOWNLOAD_DEPENDENCIES_NON_MODELED_DEPENDENCIES: 'download_dependencies_non_modeled_dependencies',
  DOWNLOAD_DEPENDENCIES_DETAILED_NON_MODELED_DEPENDENCIES:
    'download_dependencies_detailed_non_modeled_dependencies',
  // add portfolio modal
  CLICK_ADD_PORTFOLIO_NAME_INPUT: 'click_add_portfolio_name_input',
  CLICK_ADD_PORTFOLIO_DESCRIPTION_INPUT: 'click_add_portfolio_description_input',
  SELECT_ADD_PORTFOLIO_FILE: 'select_add_portfolio_file',
  VALIDATE_ADDED_PORTFOLIO: 'validate_added_portfolio',
  GO_BACK_FROM_VALIDATE_PORTFOLIO: 'go_back_from_validate_portfolio',
  SUBMIT_ADD_PORTFOLIO: 'submit_add_portfolio',
  DOWNLOAD_ADD_PORTFOLIO_ERROR_REPORT: 'download_add_portfolio_error_report',
  SHOW_ADD_PORTFOLIO_TOOLTIP_ERROR: 'show_add_portfolio_tooltip_error',
  // portfolio analysis result
  TOGGLE_VIEW_DASHBOARD: 'toggle_view_#dashboard',
  TOGGLE_VIEW_TABLE_DATA: 'toggle_view_#table_data',
  // global search
  INPUT_COMPANY_NAME: 'click_global_search',
  // run analysis modal
  VIEW_RUN_PROBABILISTIC_ANALYSIS_PAGE: 'view_run_probabilistic_analysis_page',
  VIEW_RUN_SCENARIO_ANALYSIS_PAGE: 'view_run_scenario_analysis_page',
  CLICK_RUN_ANALYSIS_NAME_INPUT: 'click_run_analysis_name_input',
  SELECT_RUN_ANALYSIS_SETTINGS: 'select_run_analysis_settings',
  SELECT_RUN_ANALYSIS_TAB: 'select_run_analysis_tab',
  CHANGE_NUM_OF_SIMULATIONS: 'change_num_of_simulations',
  CHANGE_FREQUENCY_DISTRIBUTION: 'change_frequency_distribution',
  CHANGE_FREQUENCY_MODE: 'change_frequency_mode',
  CHANGE_SEVERITY_DISTRIBUTION: 'change_severity_distribution',
  CHANGE_SEVERITY_OPTIONS: 'change_severity_options',
  TOGGLE_IGNORE_POLICY_DATES: 'toggle_ignore_policy_dates',
  TOGGLE_CALC_ACCOUNT_LEVEL_METRICS: 'toggle_calc_account_level_metrics',
  TOGGLE_INCLUDE_CUSTOMIZED_SPOF: 'toggle_include_customized_spof',
  TOGGLE_INCLUDE_ATTRITIONAL_LOSS: 'toggle_include_attritional_loss',
  TOGGLE_INCLUDE_REINSURANCE: 'toggle_include_reinsurance',
  SELECT_RUN_ANALYSIS_SCENARIO: 'select_run_analysis_scenario',
  DESELECT_RUN_ANALYSIS_SCENARIO: 'deselect_run_analysis_scenario',
  SELECT_ALL_RUN_ANALYSIS_SCENARIOS: 'select_all_run_analysis_scenarios',
  DESELECT_ALL_RUN_ANALYSIS_SCENARIOS: 'deselect_all_run_analysis_scenarios',
  SELECT_RUN_ANALYSIS_COST_COMPONENT: 'select_run_analysis_cost_component',
  DESELECT_RUN_ANALYSIS_COST_COMPONENT: 'deselect_run_analysis_cost_component',
  SELECT_ALL_RUN_ANALYSIS_COST_COMPONENTS: 'select_all_run_analysis_cost_components',
  DESELECT_ALL_RUN_ANALYSIS_COST_COMPONENTS: 'deselect_all_run_analysis_cost_components',
  RESET_RUN_ANALYSIS_SETTINGS: 'reset_run_analysis_settings',
  SAVE_RUN_ANALYSIS_SETTINGS: 'save_run_analysis_settings',
  SUBMIT_RUN_ANALYSIS: 'submit_run_analysis',
  CHANGE_SCENARION_SPOF: 'change_scenarion_spof',
  // dependencies
  CLICK_SEARCH_BY_COMPANY_NAME: 'click_search_by_company_name',
  CLICK_SEARCH_BY_SPOF_NAME: 'click_search_by_spof_name',
  SUBMIT_SEARCH_BY_COMPANY_NAME: 'submit_search_by_company_name',
  SUBMIT_SEARCH_BY_SPOF_NAME: 'submit_search_by_spof_name',
  CLEAR_COMPANY_NAME_SEARCH: 'clear_scompany_name_search',
  CLEAR_SPOF_NAME_SEARCH: 'clear_spof_name_search',
  SELECT_COMPANY: 'select_company',
  SELECT_SPOF: 'select_spof',
  GO_TO_IMPORT_MANAGEMENT_PORTAL: 'go_to_import_management_portal',
  SET_DEPENDEND_COMPANIES_VIEW_MODE: 'set_dependend_companies_view_mode',
  FETCH_COMPANIES_ADDITIONAL_DATA: 'fetch_companies_additional_data',
  FETCH_SPOF_ADDITIONAL_DATA: 'fetch_spof_additional_data',
  SELECT_COMPANY_INDUSTRY_FILTER: 'select_company_industry_filter',
  SELECT_COMPANY_COUNTRY_FILTER: 'select_company_country_filter',
  SELECT_COMPANY_SIZE_FILTER: 'select_company_size_filter',
  REMOVE_COMPANY_FILTER: 'remove_company_filter',
  REMOVE_SPOF_FILTER: 'remove_spof_filter',
  REMOVE_ALL_FILTERS: 'remove_all_filters',
  SORT_COMPANIES_BY: 'sort_companies_by',
  SORT_SPOF_BY: 'sort_spof_by',
  SELECT_SPOF_GROUP_FILTER: 'select_spof_group_filter',
  SELECT_SPOF_TYPE_FILTER: 'select_spof_type_filter',
  SELECT_SPOF_ALL_FILTER: 'select_spof_all_filter',
  SHOW_COMPANY_DEPENDENCIES: 'show_company_dependencies',
  SHOW_SPOF_DEPENDENCIES: 'show_spof_dependencies',
  TOGGLE_COMPANY_RESULTS_MODAL: 'toggle_company_results_modal',
  TOGGLE_SPOF_RESULTS_MODAL: 'toggle_spof_results_modal',
  TOGGLE_EDIT_SPOF_DEPENDENCIES_MODAL: 'toggle_edit_spof_dependencies_modal',
  TOGGLE_REVERT_SPOF_DEPENDENCIES_MODAL: 'toggle_revert_spof_dependencies_modal',
  REVERT_SPOF_DEPENDENCIES: 'revert_spof_dependencies',
  ADD_SPOF_PROVIDER_TYPE: 'add_spof_provider_type',
  ADD_SPOF_PROVIDER: 'add_spof_provider',
  REMOVE_SPOF_PROVIDER: 'remove_spof_provider',
  TOGGLE_RESET_EDIT_SPOF_DEPENDENCIES_MODAL: 'toggle_reset_edit_spof_dependencies_modal',
  CONFIRM_RESET_EDIT_SPOF_DEPENDENCIES_MODAL: 'confirm_reset_edit_spof_dependencies_modal',
  SAVE_EDIT_SPOF_DEPENDENCIES: 'save_edit_spof_dependencies',
  CONFIRM_SAVE_EDIT_SPOF_DEPENDENCIES: 'confirm_save_edit_spof_dependencies',
  CANCEL_SAVE_EDIT_SPOF_DEPENDENCIES: 'cancel_save_edit_spof_dependencies',
  SHOW_ALL_SUMMARY_STATISTICS: 'show_all_summary_statistics',
  AAL_STDAL_CV_TABS: 'aal_stdal_cv_tabs',
  // company information
  SET_COMPANY_INFORMATION_TABLE_FILTER: 'set_company_information_table_filter',
  CLICK_SEARCH_COMPANY_INFORMATION: 'click_search_company_information',
  // analysis results
  CLICK_SEARCH_ANALYSIS_RESULTS: 'click_search_analysis_results',
  ANALYSIS_RESULTS_SIDE_MENU: 'analysis_results_side_menu',
  SORT_ANALYSIS_RESULTS_MENU: 'click_analysis_results_menu',
  COMPARE_ANALYSIS_RESULTS_MODAL: 'compare_analysis_results_modal',
  RENAME_ANALYSIS_RESULTS_MODAL: 'rename_analysis_results_modal',
  REMOVE_ANALYSIS_RESULTS_MODAL: 'remove_analysis_results_modal',
  SELECT_ANALYSIS_RESULT: 'select_analysis_result',
  RESULTS_BY_PORTFOLIO: 'results_by_portfolio',
  RESULTS_BY_SIMULATION_YEAR: 'results_by_simulation_year',
  RESULTS_BY_INDUSTRY: 'results_by_industry',
  RESULTS_BY_COMPANY_SIZE: 'results_by_company_size',
  RESULTS_BY_GEOGRAPHY: 'results_by_geography',
  RESULTS_BY_CONTRACT_TYPE: 'results_by_contract_type',
  RESULTS_BY_CUSTOM_ID1: 'results_by_custom_id1',
  RESULTS_BY_CUSTOM_ID2: 'results_by_custom_id2',
  RESULTS_BY_COMPANY: 'results_by_company',
  RESULTS_BY_SCENARIO: 'results_by_scenario',
  RESULTS_BY_REINSURANCE_TREATIES: 'results_by_reinsurance_treaties',
  RESULTS_BY_2D_INDUSTRY_SIZE: 'results_by_2d_industry_size',
  RESULTS_BY_2D_INDUSTRY_REGION: 'results_by_industry_region',
  RESULTS_BY_2D_SIZE_REGION: 'results_by_size_region',
  ANALYSIS_SETTINGS: 'analysis_settings',

  //analysis views components
  GROSS_NET_TABS: 'gross_net_tabs',
  GROSS_NET_CEDED_TABS: 'gross_net_ceded_tabs',
  EXCEEDANCE_PROBABILITY_TABLE: 'exceedance_probability_table',
  OEP_AEP_TCE_TABS: 'oep_aep_tce_tabs',
  SCENARIOS_INCLUDED_EXPAND_BUTTON: 'scenarios_included_expand_button',
  COST_COMPONENT_COVERAGE_MAPPING_BUTTON: 'cost_component_coverage_mapping_button',
  COST_COMPONENT_COVERAGE_MAPPING_MODAL_CANCEL_BUTTON:
    'cost_component_coverage_mapping_modal_cancel_button',
  CONTRACT_TYPE_COVERAGE_MAPPING_BUTTON: 'contract_type_coverage_mapping_button',
  CONTRACT_TYPE_COVERAGE_MAPPING_MODAL_CANCEL_BUTTON:
    'contract_type_coverage_mapping_modal_cancel_button',
  POLICY_WORDING_AND_EXCLUSIONS_DEFAULT_BUTTON: 'policy_wording_and_exclusions_default_button',
  POLICY_WORDING_AND_EXCLUSIONS_MODAL_TABLE: 'policy_wording_and_exclusions_modal_table',
  POLICY_WORDING_AND_EXCLUSIONS_MODAL_CANCEL_BUTTON:
    'policy_wording_and_exclusions_modal_cancel_button',
  VIEW_RESULTS_BY_COMPANY_NO_PREVIEW: 'view_results_by_company_no_preview',
  // analysis reports
  VIEW_ANALYSIS_REPORTS_PAGE: 'view_analysis_reports_page',
  SET_ANALYSIS_REPORTS_TABLE_FILTER: 'set_analysis_reports_table_filter',
  CLICK_ANALYSIS_REPORTS_SEARCH_INPUT: 'click_analysis_reports_search_input',
  SELECT_ALL_ANALYSIS_REPORTS: 'select_all_analysis_reports',
  DESELECT_ALL_ANALYSIS_REPORTS: 'deselect_all_analysis_reports',
  SELECT_ANALYSIS_REPORT: 'select_analysis_report',
  DESELECT_ANALYSIS_REPORT: 'deselect_analysis_report',
  SORT_ANALYSIS_REPORTS: 'sort_analysis_reports',
  TOGGLE_REMOVE_ANALYSIS_REPORT_MODAL: 'toggle_remove_analysis_report_modal',
  TOGGLE_RENAME_ANALYSIS_REPORT_MODAL: 'toggle_rename_analysis_report_modal',
  TOGGLE_SHARE_ANALYSIS_REPORT_MODAL: 'toggle_share_analysis_report_modal',
  TOGGLE_COMPARE_ANALYSIS_REPORT_MODAL: 'toggle_compare_analysis_report_modal',
  TOGGLE_REFERENCE_ANALYSIS_MODAL: 'toggle_reference_analysis_modal',
  SHOW_DONWLOAD_REPORTS_OPTIONS: 'show_donwload_reports_options',
  SUBMIT_RENAME_ANALYSIS: 'submit_rename_analysis',
  SUBMIT_REMOVE_ANALYSIS: 'submit_remove_analysis',
  DOWNLOAD_ANALYSIS_REPORT: 'download_analysis_report',
  REMOVE_SELECTED_ANALYSIS_REPORTS: 'remove_selected_analysis_reports',
  SELECT_INCLUDE_SHARE_PORTFOLIO: 'select_include_share_portfolio',
  DESELECT_INCLUDE_SHARE_PORTFOLIO: 'deselect_include_share_portfolio',
  SELECT_USER_TO_SHARE_ANALYSIS_REPORTS: 'select_user_to_share_analysis_reports',
  REMOVE_USER_TO_SHARE_ANALYSIS_REPORTS: 'remove_user_to_share_analysis_reports',
  REVIEW_SHARE_ANALYSIS_REPORT: 'review_share_analysis_report',
  GO_BACK_FROM_CONFIRM_SHARE_ANALYSIS_REPORT: 'go_back_from_confirm_share_analysis_report',
  SUBMIT_SHARE_ANALYSIS_REPORT: 'submit_share_analysis_report',
  CHANGE_ANALYISIS_COMPARISON_FILTER: 'change_analyisis_comparison_filter',
  CHANGE_ANALYISIS_COMPARISON_TAB: 'change_analyisis_comparison_tab',
  SUBMIT_ANALYSIS_COMPARISON: 'submit_analysis_comparison',
  VIEW_PROBABILISTIC_ANALYSIS_RESULTS_PAGE: 'view_probabilistic_analysis_results_page',
  VIEW_SCENARIO_ANALYSIS_RESULTS_PAGE: 'view_scenario_analysis_results_page',
  SELECT_ANALYSIS_RESULTS_TAB: 'select_analysis_results_tab',
  PRICING_CONSISTENCY_AAL_PREMIUM_CONTRIBUTION_CHART:
    'pricing_consistency_aal_premium_contribution_chart',
  TOGGLE_SCENARIO_ANALYSIS_SCENARIO_DETAILS_MODAL:
    'toggle_scenario_analysis_scenario_details_modal',
  CHANGE_SCENARIO_ANALYSIS_SCENARIO_DETAILS_TAB: 'change_scenario_analysis_scenario_details_tab',
  TOGGLE_SELECT_USER_TO_SHARE_REFERENCE_ANALYSIS: 'toggle_select_user_to_share_reference_analysis',
  REMOVE_USER_FROM_SHARE_REFERENCE_ANALYSIS: 'remove_user_from_share_reference_analysis',
  REVIEW_SHARE_REFERENCE_ANALYSIS: 'review_share_reference_analysis',
  GO_BACK_FROM_CONFIRM_SHARE_REFERENCE_ANALYSIS: 'go_back_from_confirm_share_reference_analysis',
  SUBMIT_SHARE_REFERENCE_ANALYSIS: 'submit_share_reference_analysis',
  // spof metrics
  VIEW_SPOF_METRICS_PAGE: 'view_spof_metrics_page',
  SELECT_SPOF_METRICS_GROUP: 'select_spof_metrics_group',
  SELECT_SPOF_METRICS_GROUP_TYPE: 'select_spof_metrics_group_type',
  SELECT_SPOF_METRICS_TYPE: 'select_spof_metrics_type',
  CLICK_SEARCH_SPOF_METRICS: 'click_search_spof_metrics',
  SELECT_SPOF_METRICS_TABLE_VIEW: 'select_spof_metrics_table_view',
  SORT_SPOF_METRICS_BY: 'sort_spof_metrics_by',
  DOWNLOAD_SPOF_METRICS: 'download_spof_metrics',
  VIEW_SPOF_METRICS_DETAILS: 'view_spof_metrics_details',
  VIEW_SPOF_METRICS_DEPENDENCIES: 'view_spof_metrics_dependencies',
  FETCH_SPOF_METRICS_ADDITIONAL_DATA: 'fetch_spof_metrics_additional_data',
  // spof details
  DOWNLOAD_SPOF_DETAILS: 'download_spof_details',
  TOGGLE_SPOF_DETAILS_BREACHES_MODAL: 'toggle_spof_details_breaches_modal',
  TOGGLE_SPOF_DETAILS_OUTAGES_MODAL: 'toggle_spof_details_outages_modal',
  TOGGLE_SPOF_DETAILS_VULNERABILITIES_MODAL: 'toggle_spof_details_vulnerabilities_modal',
  CLICK_SEARCH_SPOF_OUTAGES: 'click_search_spof_outages',
  SORT_SPOF_OUTAGES_BY: 'sort_spof_outages_by',
  FETCH_SPOF_OUTAGES_ADDITIONAL_DATA: 'fetch_spof_outages_additional_data',
  SORT_SPOF_BREACHES_BY: 'sort_spof_breaches_by',
  FETCH_SPOF_BREACHES_ADDITIONAL_DATA: 'fetch_spof_breaches_additional_data',
  SORT_SPOF_VULNERABILITIES_BY: 'sort_spof_vulnerabilities_by',
  FETCH_SPOF_VULNERABILITIES_ADDITIONAL_DATA: 'fetch_spof_vulnerabilities_additional_data',
  // import management
  VIEW_IMPORT_MANAGEMENT_PAGE: 'view_import_management_page',
  SELECT_IMPORT_MANAGEMENT_SPOF_TAB: 'select_import_management_spof_tab',
  SELECT_IMPORT_MANAGEMENT_REINSURANCE_TAB: 'select_import_management_reinsurance_tab',
  TOGGLE_UPLOAD_SPOF_DEPENDENCIES_MODAL: 'toggle_upload_spof_dependencies_modal',
  DOWNLOAD_SPOF_DEPENDENCIES_TEMPLATE: 'download_spof_dependencies_template',
  SELECT_SPOF_DEPENDECIES_FILE: 'select_spof_dependecies_file',
  VALIDATE_SPOF_DEPENDENCIES_FILE: 'validate_spof_dependencies_file',
  SUBMIT_UPLOAD_SPOF_DEPENDENCIES_FILE: 'submit_upload_spof_dependencies_file',
  GO_BACK_FROM_VALIDATE_SPOF_DEPENDENCIES_FILE: 'go_back_from_validate_spof_dependencies_file',
  TOGGLE_SPOF_DEPENDENCIES_CUSTOMIZATION_HISTORT_MODAL:
    'toggle_spof_dependencies_customization_histort_modal',
  REVERT_SPOF_DEPENDENCIES_TO_DEFAULT: 'revert_spof_dependencies_to_default',
  SELECT_SPOF_DEPENDENCIES_CUSTOMIZATION_HISTORT_TAB:
    'select_spof_dependencies_customization_histort_tab',
  // treaties
  TOGGLE_ADD_TREATY_MODAL: 'toggle_add_treaty_modal',
  TOGGLE_EDIT_TREATY_MODAL: 'toggle_edit_treaty_modal',
  SUBMIT_ADD_TREATY: 'submit_add_treaty',
  SUBMIT_EDIT_TREATY: 'submit_edit_treaty',
  SUBMIT_DELETE_TREATY: 'submit_delete_treaty',
  // platform settings
  TOGGLE_COST_COMPONENT_SETTINGS_MODAL: 'toggle_cost_component_settings_modal',
  TOGGLE_CONTRACT_TYPE_SETTINGS_MODAL: 'toggle_contract_type_settings_modal',
  CLOSE_PLATFORM_SETTINGS_MODAL: 'close_platform_settings_modal',
  SELECT_COVERAGE_SETTINGS: 'select_coverage_settings',
  DESELECT_COVERAGE_SETTINGS: 'deselect_coverage_settings',
  RESET_COVERAGE_SETTINGS: 'reset_coverage_settings',
  SAVE_COVERAGE_SETTINGS: 'save_coverage_settings',
  // policy wordings
  VIEW_POLICY_WORDING_PAGE: 'view_policy_wording_page',

  //am features
  ANALYSIS_RESULT_PAGE: 'analysis_result_page',
  PORTFOLIO_ANALYSIS_RESULT_PAGE: 'portfolio_analysis_result_page',
  ADD_ACCOUNT_MODAL: 'add_account_modal',
  SCORING_ANALYSIS_RESULT_PAGE: 'scoring_analysis_result_page',
  SCORING_ANALYSIS_HISTORY_MODAL: 'scoring_analysis_history_modal',
  FINANCIAL_LOSS_MODEL: 'financial_loss_model',
  SCORE_DETAILS: 'score_details',
  SCORE_DETAILS_PAGE: 'score_details_page',
  SCORE_RANK_AND_HISTORY_PAGE: 'score_rank_and_history_page',
  SECURITY_SCORE_BREAKDOWN: 'security_score_breakdown',
  SECURITY_SCORE_BREAKDOWN_SIGNAL: 'security_score_breakdown_signal',
  SECURITY_SIGNALS: 'security_signals',
  TECHNOLOGY_DEPENDENCIES_PAGE: 'technology_dependencies_page',
  NAVIGATION_MENU_ACTION: 'navigation_menu_action',
  NAVIGATION_SUB_MENU_ACTION: 'navigation_sub_menu_action',
  HEADER_NAVIGATION_MENU_ACTION: 'header_navigation_menu_action',
  HEADER_NAVIGATION_SUB_MENU_ACTION: 'header_navigation_sub_menu_action',
  DELETE_ANALYSIS_MODAL: 'delete_analysis_modal',
  MARGINAL_RISK_PAGE: 'marginal_risk_page',
  CUSTOM_REPORT_DOWNLOAD_MODAL: 'custom_report_download_modal',
  ODS_MODAL: 'ods_modal',
  ODS_CONFIGURE_TAB: 'ods_configure_tab',
  ODS_RESULTS_TAB: 'ods_results_tab',
  RERUN_ANALYSIS_MODAL: 'rerun_analysis_modal',
  DELETE_ACCOUNT_MODAL: 'delete_account_modal',

  //am events
  DOWNLOAD_REPORT_MENU: 'download_report_menu',
  DOWNLOAD_LATEST_REPORT: 'download_latest_report',
  DOWNLOAD_SUMMARY_REPORT: 'download_summary_report',
  DOWNLOAD_CUSTOM_REPORT: 'download_custom_report',
  DOWNLOAD_RFA_YLT: 'download_rfa_ylt',
  DOWNLOAD_BASELINE_YLT: 'download_baseline_ylt',
  DOWNLOAD_CAT_YLT: 'download_cat_ylt',
  DOWNLOAD_FILTERED_VIEW: 'download_filtered_view',
  DOWNLOAD_TECH_DEPS_CSV: 'download_tech_deps_csv',
  DOWNLOAD_EXCEEDANCE_PROBABILITY_CSV: 'download_exceedance_probability_csv',
  DELETE_ACCOUNT: 'delete_account',
  DELETE_ANALYSIS: 'delete_analysis',
  CREATE_NEW_ACCOUNT: 'create_new_account',
  PROCEED_NEW_ACCOUNT: 'proceed_new_account',
  CANCEL_CREATE_NEW_ACCOUNT: 'cancel_create_new_account',
  ACCOUNT_HISTORY: 'account_history',
  FINANCIAL_LOSS_SEE_ALL: 'financial_loss_see_all',
  SECURITY_SCORE_SEE_ALL: 'security_score_see_all',
  PAST_CYBER_SEE_ALL: 'past_cyber_see_all',
  TECH_DEPS_SEE_ALL: 'tech_deps_see_all',
  RISK_FACTORS_SEE_ALL: 'risk_factors_see_all',
  TOGGLE_RANK: 'toggle_rank',
  TOGGLE_SCORE: 'toggle_score',
  BACK_TO_CONTROL_CATEGORY_BREAKDOWN: 'back_to_control_category_breakdown',
  LOSS_MODEL_BASELINE_VIEW: 'loss_model_baseline_view',
  LOSS_MODEL_RFA_VIEW: 'loss_model_rfa_view',
  CONFIDENCE_FILTER_DROPDOWN: 'confidence_filer_dropdown',
  IMPORTANCE_FILTER_DROPDOWN: 'importance_filer_dropdown',
  SIGNALS_FILTER: 'signals_filter',
  SCORING_ANALYSIS_PAGE_NAVIGATION: 'scoring_analysis_page_navigation',
  FINISH_STEP: 'finish_step',
  THREAT_TYPE_FILTER: 'threat_type_filter',
  SEGMENT_TYPE_FILTER: 'segment_type_filter',
  SELECTED_CRITICAL_RISK_FACTORS: 'selected_critical_risk_factors',
  TOP_PREDICTIVE_RISK_SIGNALS: 'top_predictive_risk_signals',
  ODS_SNAPSHOT_REPORT_MENU: 'ods_snapshot_report_menu',
  ODS_PDF_DOWNLOAD: 'ods_pdf_download',
  ODS_XLSX_DOWNLOAD: 'ods_xlsx_download',
  ODS_START_SCAN_DOMAIN: 'ods_start_scan_domain',
  ODS_START_SCAN_IP: 'ods_start_scan_ip',
  ODS_DOMAIN_LIMIT_EXCEEDED_ERROR: 'ods_domain_limit_exceeded_error',
  ODS_DOMAIN_ALREADY_EXISTS_ERROR: 'ods_domain_already_exists_error',
  ODS_ADD_DOMAIN: 'ods_add_domain',
  ODS_ADD_IP: 'ods_add_ip',
  ODS_DOMAIN_RADIO: 'ods_domain_radio',
  ODS_IP_RADIO: 'ods_domain_radio',
  MEATBALLS_MENU: 'meatballs_menu',
  COMPANY_ANALYSIS_NAME_LINK: 'company_analysis_name_link',
  BACK_BUTTON: 'back_button',
  RUN_ANALYSIS_NEXT: 'run_analysis_next',
  RUN_ANALYSIS: 'run_analysis',
  RUN_ANALYSIS_STEP_TAB: 'run_analysis_step_tab',
  MONTH_DROPDOWN: 'month_dropdown',
} as const;
