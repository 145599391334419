import { createBrowserRouter, Navigate, RouteObject } from 'react-router-dom';
import AppLoader from './components/AppLoader';

export const pdfAppRoutes: RouteObject[] = [
  {
    path: '/pdf',
    children: [
      {
        index: true,
        element: <Navigate to="am" replace />,
      },
      {
        path: 'am/*',
        element: <AppLoader loadComponent={() => import('AccountManager/App')} />,
      },
    ],
  },
  {
    path: '*',
    element: <Navigate to="/pdf" replace />,
  },
];

export const pdfAppRouter = createBrowserRouter(pdfAppRoutes);
